import React from 'react';

// Styles
import './TeamMember.styles.scss';

const TeamMember = ({
    name,
    imgURL,
    title,
    bio
}) => (
    <div className="team-member">
        <div className="team-member__photo-wrapper">
            <img
                className="team-member__photo"
                src={imgURL}
                alt={`Photo of team member: ${name}`}
            />
            <h3 className="team-member__name">
            { name }
        </h3>
        </div>
        
        <h4 className="team-member__title">
            { title }
        </h4>
        <p className="team-member__bio">
            { bio }
        </p>
    </div>
);

export default TeamMember;