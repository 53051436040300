import React from "react"

// Components
import TeamMember from '../components/TeamMember';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Navbar from '../components/Navbar';

// Assets
import JoshPicURL from '../images/Josh.jpg';

// Styles
import './about.styles.scss'

const teamMembers = [
    {
        name: "Josh Levine",
        imgURL: JoshPicURL,
        title: "Founder & CEO",
        bio: "Josh Levine is the Founder and CEO of Transferable, a higher education technology firm dedicated to improving the transfer process by supporting meaningful connections between students and universities. Josh has spent his professional career delving deeply into the business models, challenges, and opportunities of higher ed organizations. This started at Moody’s, where Josh served as a lead analyst and wrote the firm’s methodology on community colleges. Subsequently, he managed credit risk for a multi-billion-dollar portfolio of higher ed-focused investments at Lord Abbett, where he also published in-depth research. Josh has a Master’s in Public Affairs from the LBJ School of Public Affairs and served as the school’s Alumni Board President from 2017-2019. When he’s not thinking about education, Josh enjoys playing sports (especially tennis), trying new restaurants, going to comedy shows, and cheering for the Dallas Mavericks.",
    },
];

const AboutPage = () => (
  <Layout currentPath="/about">
    <SEO title="About" />
    <div className="about-page">
        <Navbar />
        <section className="about-page__body">
            <div className="about-page__header">
                <h1 className="about-page__heading">
                    What We're About
                </h1>

                <p className="about-page__blurb">
                    Transferable is the leading higher education technology 
                    firm dedicated to improving the transfer process by 
                    supporting meaningful connections between students 
                    and universities. We’re on a mission to help students 
                    reach their full potential.
                </p>
            </div>

                <div className="about-page__our-team">
                    <h3 className="about-page__our-team-heading">
                        Our Leadership Team
                    </h3>

                    <div className="about-page__our-team-grid">

                        { teamMembers.map(tm => (
                            <TeamMember
                                name={tm.name}
                                imgURL={tm.imgURL}
                                title={tm.title}
                                bio={tm.bio}
                            />
                        ))}
                    </div>
                </div>
            </section>
        </div>
  </Layout>
)

export default AboutPage;
